/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Account } from '../models/account';
import { getAccounts } from '../fn/account/get-accounts';
import { GetAccounts$Params } from '../fn/account/get-accounts';
import { getAccountsToDisplay } from '../fn/account/get-accounts-to-display';
import { GetAccountsToDisplay$Params } from '../fn/account/get-accounts-to-display';
import { patchAccounts } from '../fn/account/patch-accounts';
import { PatchAccounts$Params } from '../fn/account/patch-accounts';

@Injectable({ providedIn: 'root' })
export class AccountApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAccounts()` */
  static readonly GetAccountsPath = '/accounts';

  /**
   * Retrieve all the accounting accounts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccounts$Response(params?: GetAccounts$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Account>>> {
    return getAccounts(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieve all the accounting accounts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccounts(params?: GetAccounts$Params, context?: HttpContext): Observable<Array<Account>> {
    return this.getAccounts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Account>>): Array<Account> => r.body)
    );
  }

  /** Path part for operation `patchAccounts()` */
  static readonly PatchAccountsPath = '/accounts';

  /**
   * Patch accounts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchAccounts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchAccounts$Response(params: PatchAccounts$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Account>>> {
    return patchAccounts(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch accounts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchAccounts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchAccounts(params: PatchAccounts$Params, context?: HttpContext): Observable<Array<Account>> {
    return this.patchAccounts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Account>>): Array<Account> => r.body)
    );
  }

  /** Path part for operation `getAccountsToDisplay()` */
  static readonly GetAccountsToDisplayPath = '/accounts/display';

  /**
   * Retrieve all the accounting accounts to be displayed.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccountsToDisplay()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountsToDisplay$Response(params?: GetAccountsToDisplay$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Account>>> {
    return getAccountsToDisplay(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieve all the accounting accounts to be displayed.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccountsToDisplay$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountsToDisplay(params?: GetAccountsToDisplay$Params, context?: HttpContext): Observable<Array<Account>> {
    return this.getAccountsToDisplay$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Account>>): Array<Account> => r.body)
    );
  }

}
