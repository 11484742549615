/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getVatCodes } from '../fn/vat-codes/get-vat-codes';
import { GetVatCodes$Params } from '../fn/vat-codes/get-vat-codes';
import { getVatCodesToDisplay } from '../fn/vat-codes/get-vat-codes-to-display';
import { GetVatCodesToDisplay$Params } from '../fn/vat-codes/get-vat-codes-to-display';
import { patchVatCodes } from '../fn/vat-codes/patch-vat-codes';
import { PatchVatCodes$Params } from '../fn/vat-codes/patch-vat-codes';
import { VatCode } from '../models/vat-code';

@Injectable({ providedIn: 'root' })
export class VatCodesApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getVatCodes()` */
  static readonly GetVatCodesPath = '/vat-codes';

  /**
   * Retrieve all the vat codes.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVatCodes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVatCodes$Response(params?: GetVatCodes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<VatCode>>> {
    return getVatCodes(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieve all the vat codes.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVatCodes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVatCodes(params?: GetVatCodes$Params, context?: HttpContext): Observable<Array<VatCode>> {
    return this.getVatCodes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VatCode>>): Array<VatCode> => r.body)
    );
  }

  /** Path part for operation `patchVatCodes()` */
  static readonly PatchVatCodesPath = '/vat-codes';

  /**
   * Patch VAT codes.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchVatCodes()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchVatCodes$Response(params: PatchVatCodes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<VatCode>>> {
    return patchVatCodes(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch VAT codes.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchVatCodes$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchVatCodes(params: PatchVatCodes$Params, context?: HttpContext): Observable<Array<VatCode>> {
    return this.patchVatCodes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VatCode>>): Array<VatCode> => r.body)
    );
  }

  /** Path part for operation `getVatCodesToDisplay()` */
  static readonly GetVatCodesToDisplayPath = '/vat-codes/display';

  /**
   * Retrieve all the VAT codes to be displayed.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVatCodesToDisplay()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVatCodesToDisplay$Response(params?: GetVatCodesToDisplay$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<VatCode>>> {
    return getVatCodesToDisplay(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieve all the VAT codes to be displayed.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVatCodesToDisplay$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVatCodesToDisplay(params?: GetVatCodesToDisplay$Params, context?: HttpContext): Observable<Array<VatCode>> {
    return this.getVatCodesToDisplay$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VatCode>>): Array<VatCode> => r.body)
    );
  }

}
